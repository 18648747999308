export const formatAsTwoDigits = (input: number): string => {
  if (input < 10) return `0${input}`;
  return input.toString();
};

export const formatAsTwoDecimals = (input: number): number => {
  if (Number.isNaN(input)) {
    return input;
  }
  return parseFloat(input.toFixed(2));
};

export const formatPercentage = (input: number | string): string => {
  if (typeof input === "string") return input;
  return `${((input ?? 0) * 100).toFixed(2)}%`;
};

export const isNumeric = (value: any) => {
  return typeof value === "number" && !isNaN(value);
};
